<template>
  <!--预约商品详情-->
  <div class="yuYueProductDetails">
    <!-- 内容 -->
    <div class="content">
      <h2 class="title">
        <span class="title_icon"></span>
        基础信息
      </h2>
      <el-form label-position="right" style="padding-left:100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="停车场名称:">
              <span class="spanA">{{formInline.slaveRelation}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品状态:">
              <span class="spanA">{{formInline.productState}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="预约日期：" prop="searchDateS">
          <span class="spanA">{{formInline.searchDateS[0]}}至{{formInline.searchDateS[1]}}</span>
          <!-- <span class="spanA">2019-11-12至2020-11-12</span> -->
        </el-form-item>
        <el-form-item label="预约时间：" prop="searchDateS">
          <span class="spanA">{{formInline.startTime}} - {{formInline.endTime}}</span>
          <!-- <span class="spanA">20:00 - 08:00</span> -->
        </el-form-item>

        <el-form-item label="泊位保留时长:" prop="timer">
          <span class="spanA">{{formInline.timer}} 分钟</span>
        </el-form-item>
      </el-form>
      <h2 class="title">
        <span class="title_icon"></span>
        销售信息
      </h2>
      <el-form label-position="right" style="padding-left:100px">
        <el-form-item label="预约泊位数量:" prop="appointmentBerthNumber" class="parknum">
          <span class="spanA">{{formInline.appointmentBerthNumber}}个</span>
        </el-form-item>
        <el-form-item label="押金:" prop="yajin">
          <span class="spanA">{{formInline.yajin}}</span> <span v-if="formInline.yajin!='免押金'">元</span>
        </el-form-item>
        <el-form-item label="用户可提前" prop="tiqian">
          <span class="spanA" style="margin-left: -12px">
            <span
              style="color:blue"
            >{{formInline.tiqian}} </span><span>{{formInline.ytimeType==0?"分钟预约车位":"天预约车位"}}</span>
          </span>
        </el-form-item>
        <el-form-item label="用户可提前" prop="quxiao" v-if="formInline.yajin!='免押金'">
          <span class="spanA" style="margin-left: -12px">
            <span style="color:blue">{{formInline.quxiao == -1 ? "":formInline.quxiao}}</span><span>{{formInline.quxiao == -1 ?":不可取消":"分钟之前自主取消预约"}}</span>
          </span>
        </el-form-item>
        <el-form-item label="预约说明:" prop="textarea">
          <span class="spanA">{{formInline.textarea}}</span>
        </el-form-item>
      </el-form>
      <div style="margin:0 auto;width:250px;margin-bottom:20px;">
        <el-button type="button" @click="goback">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  data() {
    return {
      formInline: {
        searchDateS: [],
        slaveRelation: "",
        productState: "",
        appointmentBerthNumber: "1",
        timer: "",
        yajin: "",
        tiqian: "",
        quxiao: "",
        tuihuan: "",
        textarea: "",
        startTime: "",
        endTime: "",
        ytimeType: "",
        cancelType: "",

      },
      slaveRelation: [
        {
          code: "1",
          desc: "中关村大厦停车场",
        },
        {
          code: "2",
          desc: "中关村大厦停车场2",
        },
      ],
    };
  },
  created() {
    // console.log(this.$route.query);
    let appointmentGoodId = this.$route.query.appointmentGoodId;

    this.inItData(appointmentGoodId);
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    inItData(id) {
      this.$axios
        .post("/acb/2.0/parkAppointmentGood/getById", {
          data: {
            appointmentGoodId: id,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.inItData2(res.value);
          }
        });

      // this.formInline.searchDateS = ["2020-06-20", "2020-06-21"];
      // this.formInline.slaveRelation = "1";
      // this.formInline.type = ["小库"];
      // this.formInline.resource = "1";
      // this.formInline.resourceNum = "1";
      // this.formInline.timer = parseInt("60");
      // this.formInline.yajin = parseInt("100");
      // this.formInline.tiqian = parseInt("10");
      // this.formInline.quxiao = parseInt("3");
      // this.formInline.tuihuan = "100.5";
      // this.formInline.textarea = "不知道";
      // this.formInline.startTime = "9:30";
      // this.formInline.endTime = "10:00";
      // this.formInline.productState = "出售中";
    },
    inItData2(res) {
      // 0未上架,1出售中,2已下架
      let stateDes = "";
      if (res.goodState == 0) {
        stateDes = "未上架";
      } else if (res.goodState == 1) {
        stateDes = "出售中";
      } else if (res.goodState == 2) {
        stateDes = "已下架";
      } else {
      }

      this.formInline.slaveRelation = res.parkName;
      this.formInline.productState = stateDes;
      this.formInline.searchDateS = [
        res.appointmentStartDate,
        res.appointmentEndDate,
      ];
      this.formInline.startTime = res.appointmentStartTime;
      this.formInline.endTime = res.appointmentEndTime;
      // 泊位保留时长
      this.formInline.timer = res.berthRetentionTime;
      this.formInline.appointmentBerthNumber =
        res.appointmentBerthNumber == -1 ? "不限" : res.appointmentBerthNumber;
      this.formInline.yajin =
        res.depositMoney == -1 ? "免押金" : res.depositMoney / 100;

      if (res.advanceDate) {
        this.formInline.tiqian = res.advanceDate;
        this.formInline.ytimeType = "1";
      }

      if (res.advanceMinute) {
        this.formInline.tiqian = res.advanceMinute;
        this.formInline.ytimeType = "0";
      }
      this.formInline.quxiao = res.cancellTime;

      this.formInline.textarea = res.description;
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.title {
  font-size: 14px;
}

.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .inputTip {
    margin-left: 20px;
    color: #999;
  }

  .oRelative {
    position: relative;

    span {
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}

.spanA {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
</style>
<style>
.yuYueProductDetails .el-form-item {
  margin-bottom: 2px;
}
</style>
